/**
 * The 404 component
 */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import Page from "../components/Page/Page-component";
import { ScrollToTop as useScrollToTop } from "@bkry/bowline-components/Utils";
import { ConnectWallet } from "@bkry/bowline-components/ConnectWallet";
import { useWeb3React } from "@web3-react/core";
import { useSelector } from "react-redux";
import { getWalletAttributes } from "@bkry/bowline-redux/wallets";
import { SignInWithEthereumButton } from "@bkry/bowline-components/SignInWithEthereumButton";

const FourOFour = (props, context) => {
  useScrollToTop();
  const history = useHistory();
  let location = useLocation();
  let accessMode = null;
  let accessErrorCode = null;
  let path = null;
  if (location?.state) {
    accessMode = location?.state?.accessMode;
    accessErrorCode = location?.state?.accessErrorCode;
    path = location?.state?.path;
  }

  const errorTitle = {
    404: context.t("Page not found"),
    403: context.t("You are not authorized to see this page!"),
  };

  const errorText = {
    none: "",
    connected_wallets: context.t("Please connect to your wallet first."),
    signed_in_wallets: context.t("Please sign in with Wallet first"),
    holder_wallets: context.t("You have to be a holder to view this page"),
  };

  const errorCode = accessErrorCode || 404;
  const accessErrorType = accessMode || "none";
  const { account } = useWeb3React();
  const wallet = useSelector((state) => getWalletAttributes(state, account));

  useEffect(() => {
    if (accessErrorCode === 403 && path) {
      if (
        (accessMode === "connected_wallets" && account) ||
        (accessMode === "signed_in_wallets" && wallet)
      ) {
        history.replace(path);
      }
    }
  }, [accessErrorCode, accessMode, path, account, wallet]);
  return (
    <Page id="404" title={errorTitle[errorCode]}>
      <section className="fourofour__hero">
        <Container>
          <Row>
            <Col
              xs={{ size: 12 }}
              md={{ size: 6, offset: 3 }}
              className="text-center"
            >
              <h1>{errorTitle[errorCode]}</h1>
              <p>{errorText[accessErrorType]}</p>
              {!account && (
                <ConnectWallet
                  buttonColor="primary"
                  buttonSize="xl"
                  buttonId="nav-connect-wallet"
                  block
                />
              )}
              {account && !wallet && (
                <SignInWithEthereumButton
                  color="primary"
                  size="xl"
                  block
                  onSuccess={() => {}}
                />
              )}

              {account && wallet && !wallet.current_wallet_is_holder && (
                <Link to={`/minting`} className="btn btn-primary w-100">
                  {context.t("Become a Holder")}
                </Link>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              className="fourofour__container__cta"
              xs={{ size: 6, offset: 3 }}
              md={{ size: 4, offset: 4 }}
            >
              <Link to={`/`} className="btn btn-outline-primary w-100">
                {context.t("Back to Home")}
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </Page>
  );
};
/**  define proptype for the 'translation' function  */
FourOFour.contextTypes = {
  t: PropTypes.func,
};

export default FourOFour;
