import {
  getNavigationItemAttributes,
  getNavigationItemRelationships,
  getNavigationItems,
} from "@bkry/bowline-redux/navigationItems";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { NavItem, NavLink as NavbarLink, Nav } from "reactstrap";

const HeaderNavItem = ({
  navigationItemId,
  handleOnClick,
  isOffcanvas,
  mostSpecificUrl,
  className,
}) => {
  const lang = useSelector((state) => state.i18nState.lang);
  const navigationItemData = useSelector((state) =>
    getNavigationItemAttributes(state, navigationItemId)
  );

  const navigationItemRelationships = useSelector((state) =>
    getNavigationItemRelationships(state, navigationItemId)
  );

  const nestedNavigationItems = useSelector((state) =>
    getNavigationItems(
      state,
      navigationItemRelationships?.children?.data?.map((item) => item?.id)
    )
  );

  const isPageContentType = navigationItemData?.content_type === "page";

  const parentNavigationItemRelationships = useSelector((state) =>
    getNavigationItemRelationships(
      state,
      navigationItemRelationships?.parent?.data?.id
    )
  );

  const isFirstLevel = !parentNavigationItemRelationships?.parent?.data;

  return (
    <>
      {nestedNavigationItems?.length && isFirstLevel ? (
        <NavItem className="py-3">
          <span
            className="fw-bolder mx-3 px-2 d-flex align-items-center"
            style={{ color: "#e97132" }}
          >
            <span>{navigationItemData?.title}</span>
            {navigationItemData?.title === "TX1" &&
              new Date() < new Date("2024-04-25") && (
                <>
                  &nbsp;[&nbsp;<span className="blinker">LIVE</span>&nbsp;]
                </>
              )}
          </span>
          <Nav className="flex-column mt-2">
            {nestedNavigationItems.map((childNav, index) => (
              <HeaderNavItem
                key={index}
                navigationItemId={childNav?.id}
                handleOnClick={handleOnClick}
                isOffcanvas={isOffcanvas}
                mostSpecificUrl={mostSpecificUrl}
              />
            ))}
          </Nav>
        </NavItem>
      ) : isPageContentType ? (
        <NavItem
          key={navigationItemId}
          className={`d-flex ${
            isOffcanvas ? "text-decoration-none text-wrap" : ""
          }`}
        >
          <NavLink
            to={`/${lang}/${
              navigationItemData?.url === "/" ? "" : navigationItemData?.url
            }`}
            onClick={handleOnClick}
            className={`nav-link mx-3 my-1 my-md-0 ${className}`}
            isActive={(match) =>
              !(
                !match ||
                !mostSpecificUrl ||
                mostSpecificUrl.id !== navigationItemId
              )
            }
          >
            {navigationItemData?.title}
          </NavLink>
        </NavItem>
      ) : (
        <NavItem key={navigationItemId} className={`${className}`}>
          <NavbarLink
            href={navigationItemData?.url}
            onClick={handleOnClick}
            target="_blank"
            rel="noopener noreferrer"
            className={` my-1 my-md-0`}
          >
            {navigationItemData?.title}
          </NavbarLink>
        </NavItem>
      )}

      {nestedNavigationItems?.length && !isFirstLevel
        ? nestedNavigationItems.map((childNav, index) => (
            <HeaderNavItem
              key={index}
              navigationItemId={childNav.id}
              handleOnClick={handleOnClick}
              isOffcanvas={isOffcanvas}
              mostSpecificUrl={mostSpecificUrl}
            />
          ))
        : null}
    </>
  );
};

HeaderNavItem.propTypes = {
  navigationItemId: PropTypes.string,
  handleOnClick: PropTypes.func.isRequired,
  isOffcanvas: PropTypes.bool,
  mostSpecificUrl: PropTypes.object,
  className: PropTypes.string,
};

HeaderNavItem.defaultProps = {
  isOffcanvas: false,
  mostSpecificUrl: undefined,
  className: "",
};

export default HeaderNavItem;
