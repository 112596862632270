/**
 * The Root container
 */
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import Loadable from "react-loadable";
import { useSsrEffect } from "@issr/core";
import { useWeb3React } from "@web3-react/core";
import FourOFour from "./FourOFour";
import Navbar from "../components/Navbar";
import { isServer } from "@bkry/bowline-redux";
import { ProjectOnboarding } from "@bkry/bowline-components/ProjectOnboarding";
import { showProject } from "@bkry/bowline-redux/projects";
import { useEffect } from "react";
import { env } from "@bkry/bowline-utils";
import Footer from "../components/Footer/Footer-component";
import {
  getWalletAttributes,
  setKeepWalletConnection,
} from "@bkry/bowline-redux/wallets";
import { getProjectAttributes } from "@bkry/bowline-redux/projects";
import EmailConfirmationModal from "@bkry/bowline-components/EmailConfirmationModal";
import { googleWallet } from "@bkry/bowline-connectors";
import { getEnv } from "@bkry/bowline-utils";

const { REACT_APP_PROJECT_ID } = env;

// !!!! ALSO CHANGE IN MINT PASS CARD
const REACT_APP_MINT_TIER_MX1_GENESIS_ID =
  "10f3b1e8-1e74-4a69-9892-39df602721de";
const REACT_APP_MINT_TIER_MX1_ART_ID = "80156c3a-df1b-45c7-859c-8ff6ef920806";
const REACT_APP_MINT_TIER_MX1_ART_FRIENDS_ID =
  "924c7123-9718-4ee1-b3d6-0e059a751b9b";
const REACT_APP_MINT_TIER_TX1_GENESIS_ID =
  "c8b32cc1-f714-11ee-8817-0242ac170002";

// import { PrivateRoute } from "@bkry/bowline-components/PrivateRoute";

/**
 * Loadable import for Home container
 */
// const Landingpage = Loadable({
//   loader: () => import(/* webpackChunkName: "Landingpage" */ './Landingpage'),
//   loading: () => null,
//   modules: ['Landingpage']
// });

// /**
//  * Loadable import for Dashboard container
//  */

const Gen3Dashboard = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Gen3Dashboard" */ "./Gen3Dashboard"),
  loading: () => null,
  modules: ["Gen3Dashboard"],
});

const TX1GenesisMint = Loadable({
  loader: () =>
    import(/* webpackChunkName: "TX1GenesisMint" */ "./TX1GenesisMint"),
  loading: () => null,
  modules: ["TX1GenesisMint"],
});
const Lootbox = Loadable({
  loader: () => import(/* webpackChunkName: "Lootbox" */ "./Lootbox"),
  loading: () => null,
  modules: ["Lootbox"],
});

const MX1GenesisMint = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MX1GenesisMint" */ "./MX1GenesisMint"),
  loading: () => null,
  modules: ["MX1GenesisMint"],
});

const MX1ArtMint = Loadable({
  loader: () => import(/* webpackChunkName: "MX1ArtMint" */ "./MX1ArtMint"),
  loading: () => null,
  modules: ["MX1ArtMint"],
});

const Artists = Loadable({
  loader: () => import(/* webpackChunkName: "Artists" */ "./Artists"),
  loading: () => null,
  modules: ["Artists"],
});

const Partners = Loadable({
  loader: () => import(/* webpackChunkName: "Partners" */ "./Partners"),
  loading: () => null,
  modules: ["Partners"],
});

const TermsOfService = Loadable({
  loader: () =>
    import(/* webpackChunkName: "TermsOfService" */ "./TermsOfService"),
  loading: () => null,
  modules: ["TermsOfService"],
});
const TermsOfServiceRaffle = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "TermsOfServiceRaffle" */ "./TermsOfServiceRaffle"
    ),
  loading: () => null,
  modules: ["TermsOfServiceRaffle"],
});

const DataPrivacy = Loadable({
  loader: () => import(/* webpackChunkName: "DataPrivacy" */ "./DataPrivacy"),
  loading: () => null,
  modules: ["DataPrivacy"],
});

const TX1Tos = Loadable({
  loader: () => import(/* webpackChunkName: "TX1Tos" */ "./TX1Tos"),
  loading: () => null,
  modules: ["TX1Tos"],
});

const MX1Tos = Loadable({
  loader: () => import(/* webpackChunkName: "MX1Tos" */ "./MX1Tos"),
  loading: () => null,
  modules: ["MX1Tos"],
});

const Imprint = Loadable({
  loader: () => import(/* webpackChunkName: "Imprint" */ "./Imprint"),
  loading: () => null,
  modules: ["Imprint"],
});
// const BenefitsTos = Loadable({
//   loader: () => import(/* webpackChunkName: "BenefitsTos" */ "./BenefitsTos"),
//   loading: () => null,
//   modules: ["BenefitsTos"],
// });
const TvApp = Loadable({
  loader: () => import(/* webpackChunkName: "TvApp" */ "./TvApp"),
  loading: () => null,
  modules: ["TvApp"],
});

const Cookies = Loadable({
  loader: () => import(/* webpackChunkName: "Cookies" */ "./Cookies"),
  loading: () => null,
  modules: ["Cookies"],
});

const RaffleUnlock = Loadable({
  loader: () => import(/* webpackChunkName: "RaffleUnlock" */ "./RaffleUnlock"),
  loading: () => null,
  modules: ["RaffleUnlock"],
});

const PartnerDealsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PartnerDeals" */ "./PartnerDeals/PartnerDealsRoot"
    ),
  loading: () => null,
  modules: ["PartnerDeals"],
});

const CommunityAnnouncementsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CommunityAnnouncements" */ "./CommunityAnnouncements/CommunityAnnouncementsRoot"
    ),
  loading: () => null,
  modules: ["CommunityAnnouncements"],
});

const Tribes = Loadable({
  loader: () => import(/* webpackChunkName: "Tribes" */ "./Tribes"),
  loading: () => null,
  modules: ["Tribes"],
});

const Tribe = Loadable({
  loader: () => import(/* webpackChunkName: "Tribe" */ "./Tribe"),
  loading: () => null,
  modules: ["Tribe"],
});

const Benefits = Loadable({
  loader: () => import(/* webpackChunkName: "Benefits" */ "./Benefits"),
  loading: () => null,
  modules: ["Benefits"],
});

const UnlockableContentDetail = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UnlockableContentDetail" */ "./UnlockableContents/UnlockableContentDetail"
    ),
  loading: () => null,
  modules: ["UnlockableContentDetail"],
});

const PartnerDealDetail = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PartnerDealDetail" */ "./PartnerDeals/PartnerDealDetail"
    ),
  loading: () => null,
  modules: ["PartnerDealDetail"],
});

const RaffleDetail = Loadable({
  loader: () =>
    import(/* webpackChunkName: "RaffleDetail" */ "./Raffles/RaffleDetail"),
  loading: () => null,
  modules: ["RaffleDetail"],
});

// const TX1Registration = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "TX1Registration" */ "./TX1Registration"),
//   loading: () => null,
//   modules: ["TX1Registration"],
// });

const Faqs = Loadable({
  loader: () => import(/* webpackChunkName: "Faqs" */ "./Faqs"),
  loading: () => null,
  modules: ["Faqs"],
});

const TermsAndConditions = Loadable({
  loader: () =>
    import(/* webpackChunkName: "TermsAndConditions" */ "./TermsAndConditions"),
  loading: () => null,
  modules: ["TermsAndConditions"],
});

const TermsAndConditionsTVBundle = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "TermsAndConditionsTVBundle" */ "./TermsAndConditionsTVBundle"
    ),
  loading: () => null,
  modules: ["TermsAndConditionsTVBundle"],
});

// const Whitelist = Loadable({
//   loader: () => import(/* webpackChunkName: "Whitelist" */ "./Whitelist"),
//   loading: () => null,
//   modules: ["Whitelist"],
// });

// const UnlockableContentsRoot = Loadable({
//   loader: () =>
//     import(
//       /* webpackChunkName: "UnlockableContentsRoot" */ "./UnlockableContents/UnlockableContentsRoot"
//     ),
//   loading: () => null,
//   modules: ["UnlockableContentsRoot"],
// });

// const TokensRoot = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "TokensRoot" */ "./Tokens/TokensRoot"),
//   loading: () => null,
//   modules: ["TokensRoot"],
// });

// const MintingSectionRoot = Loadable({
//   loader: () =>
//     import(
//       /* webpackChunkName: "MintingSection" */ "./Minting/MintingSectionRoot"
//     ),
//   loading: () => null,
//   modules: ["MintingSection"],
// });

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
function Root() {
  const { account } = useWeb3React();
  const history = useHistory();

  if (!isServer) window.account = account;
  if (account) console.log("CONNECTED");

  const dispatch = useDispatch();
  const dispatchShowProject = async () => {
    if (REACT_APP_PROJECT_ID) dispatch(showProject(REACT_APP_PROJECT_ID));
  };

  const urlParams = new URLSearchParams(history.location.hash);
  const sessionId = urlParams.get("#sessionId");
  const suggestedChainOptions = useSelector(
    (state) => state.projects.defaultNetworks[REACT_APP_PROJECT_ID]
  );

  useSsrEffect(dispatchShowProject);
  useEffect(dispatchShowProject, [dispatch, REACT_APP_PROJECT_ID]);

  const lang = useSelector((state) => state.i18nState.lang);

  const walletData = useSelector((state) =>
    getWalletAttributes(state, account)
  );

  const walletConfirmed = walletData?.confirmed;

  const projectData = useSelector((state) =>
    getProjectAttributes(state, REACT_APP_PROJECT_ID)
  );

  const handleConnect = () => {
    googleWallet
      .setOptions({
        chainId: suggestedChainOptions?.chain_id,
        chainHex: suggestedChainOptions?.chain_hex,
        initOptions: {
          chainId: suggestedChainOptions?.chain_hex,
          rpcTarget: suggestedChainOptions
            ? `${suggestedChainOptions?.rpc_target}${getEnv(
                "REACT_APP_INFURA_ID"
              )}`
            : undefined,
          displayName: "Ethereum Mainnet",
          blockExplorer: "https://etherscan.io",
          ticker: "ETH",
          tickerName: "Ethereum",
        },
        loginOptions:
          suggestedChainOptions?.wta_id && suggestedChainOptions?.wta_google_id
            ? {
                google: {
                  name: "Custom Auth Login",
                  verifier: suggestedChainOptions?.wta_google_verifier,
                  typeOfLogin: "google",
                  clientId: suggestedChainOptions?.wta_google_id,
                },
              }
            : undefined,
        clientId: suggestedChainOptions?.wta_id,
      })
      .then(() => googleWallet.activate())
      .then(() => {
        console.log("connected");
        dispatch(setKeepWalletConnection(true));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!isServer && sessionId && projectData) {
      handleConnect();
    }
  }, [sessionId, projectData, isServer]);

  return (
    <StickyContainer id="Root">
      <Navbar />
      {account && <ProjectOnboarding />}
      {walletData?.email_masked && !walletConfirmed && (
        <EmailConfirmationModal />
      )}
      <div id="content">
        <Switch>
          {/* <Route path={"/"} exact component={Dashboard} /> */}
          <Redirect exact from="/" to={`/${lang}/`} />
          <Route path={"/:lang/"} exact component={Gen3Dashboard} />
          <Route path={"/:lang/terms"} exact component={TermsOfService} />
          <Route
            path={"/:lang/terms-raffle"}
            exact
            component={TermsOfServiceRaffle}
          />
          <Route path={"/:lang/data-privacy"} exact component={DataPrivacy} />
          <Route path={"/:lang/tvapp"} exact component={TvApp} />
          <Route path={"/:lang/imprint"} exact component={Imprint} />
          <Route path={"/:lang/cookies"} exact component={Cookies} />
          <Route path={"/:lang/faqs"} exact component={Faqs} />
          <Route
            path={"/:lang/terms_and_conditions"}
            exact
            component={TermsAndConditions}
          />
          <Route
            path={"/:lang/disclaimer_tv_bundle"}
            exact
            component={TermsAndConditionsTVBundle}
          />
          <Route path={"/de/mx1/tos"} exact component={MX1Tos} />

          <Route path={"/:lang/tx1/genesis"} exact>
            <TX1GenesisMint mintTierId={REACT_APP_MINT_TIER_TX1_GENESIS_ID} />
          </Route>
          <Route path={"/:lang/tx1/tos"} exact>
            <TX1Tos />
          </Route>
          <Route path={"/:lang/holder-benefits-terms"} exact>
            <TermsOfServiceRaffle />
          </Route>
          {/* <Route
            path="/:lang/tx1/registration"
            exact
            component={TX1Registration}
          /> */}
          <Route path={"/:lang/gen3rator"} exact>
            <Lootbox />
          </Route>
          <Route path={"/:lang/genesis-mint"} exact>
            <MX1GenesisMint mintTierId={REACT_APP_MINT_TIER_MX1_GENESIS_ID} />
          </Route>
          <Route path={"/:lang/oberhausen-mint"} exact>
            <MX1GenesisMint mintTierId={REACT_APP_MINT_TIER_MX1_GENESIS_ID} />
          </Route>
          <Route path={"/:lang/art-mint"} exact>
            <MX1ArtMint mintTierId={REACT_APP_MINT_TIER_MX1_ART_ID} />
          </Route>
          <Route path={"/:lang/art-collection-mint"} exact>
            <MX1ArtMint mintTierId={REACT_APP_MINT_TIER_MX1_ART_FRIENDS_ID} />
          </Route>
          <Route path={"/:lang/artists"} exact component={Artists} />
          <Route path={"/:lang/community/collabs"} exact component={Partners} />
          <Route
            path={"/:lang/community/benefits/raffles/:raffleId"}
            exact
            component={RaffleDetail}
          />
          <Route
            path={"/:lang/community/benefits/deals/:partnerDealId"}
            exact
            component={PartnerDealDetail}
          />
          <Route
            path={"/:lang/community/benefits/extras/:contentId"}
            exact
            component={UnlockableContentDetail}
          />
          <Route path={"/:lang/community/benefits"} component={Benefits} />
          <Route
            path={"/:lang/community/membershiptiers"}
            exact
            component={Tribes}
          />
          <Route
            path={"/:lang/community/membershiptiers/:tribeId"}
            exact
            component={Tribe}
          />
          <Route path={"/:lang/raffle-unlock"} exact component={RaffleUnlock} />

          <Route
            path="/:lang/ledger-mx1-deal"
            exact
            component={PartnerDealsRoot}
          />

          <Route path="/:lang/news" component={CommunityAnnouncementsRoot} />
          <Redirect
            from={`/:lang/announcements/:announcementId`}
            to={`/:lang/news/:announcementId`}
          />
          <Redirect from={`/:lang/announcements`} to={`/:lang/news`} />

          {/* <PrivateRoute path={"/allowlist"} component={Whitelist} />
          <PrivateRoute path="/contents" component={UnlockableContentsRoot} /> */}

          {/* <PrivateRoute path="/tokens" component={TokensRoot} />
          <PrivateRoute path="/minting" component={MintingSectionRoot} /> */}

          {/* // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT */}
          {/* new routes */}

          <Route component={FourOFour} />
        </Switch>
      </div>
      <Footer />
    </StickyContainer>
  );
}

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = () => ({});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
