/**
 * The LanguageSwitch component
 */
import { getProjectAvailableLocales } from "@bkry/bowline-redux/formLocales";
import { showProject } from "@bkry/bowline-redux/projects";
import { getEnv } from "@bkry/bowline-utils";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import { push } from "connected-react-router";

const LanguageSwitch = ({ showLang, direction, className, ...args }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const REACT_APP_PROJECT_ID = getEnv("REACT_APP_PROJECT_ID");
  const availableLanguages = useSelector((state) =>
    getProjectAvailableLocales(state, REACT_APP_PROJECT_ID)
  );
  const localesNames = useSelector((state) => state.formLocales.localesNames);
  const localesFlags = useSelector((state) => state.formLocales.localesFlags);
  const [isOpen, setIsOpen] = useState(false);
  const [changeTimer, setChangeTimer] = React.useState(null);

  const openDropdown = () => {
    setChangeTimer(
      setTimeout(() => {
        setIsOpen(true);
      }, 250)
    );
  };

  const closeDropdown = () => {
    setChangeTimer(
      setTimeout(() => {
        setIsOpen(false);
      }, 250)
    );
  };

  const handleMouseOver = () => {
    // Clear the timer if it's running to prevent automatic closing
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }
    openDropdown();
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    // Clear the timer if it's running
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }
    closeDropdown();
  };

  // Function to handle toggle event
  const handleToggle = () => {
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }
    setChangeTimer(
      setTimeout(() => {
        setIsOpen((prevState) => !prevState);
      }, 250)
    );
  };

  const languages = availableLanguages.map((locale) => ({
    value: locale,
    label: `${localesFlags[locale]} ${localesNames[locale]}`,
  }));

  const handleI18nAction = (newLang) => {
    dispatch({ type: "REDUX_I18N_SET_LANGUAGE", lang: newLang });
    let splitUrl = location?.pathname?.split("/");
    splitUrl[1] = newLang;
    dispatch(push(splitUrl.join("/")));
    if (REACT_APP_PROJECT_ID) dispatch(showProject(REACT_APP_PROJECT_ID));
  };

  const lang = useSelector((state) => state.i18nState.lang);
  if (!availableLanguages.length || availableLanguages.length < 2) return null;
  return (
    <Dropdown
      isOpen={isOpen}
      toggle={handleToggle}
      className={className}
      direction={direction}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <DropdownToggle caret color="link" size="sm" className="nav-link">
        <span className="text-uppercase">{lang}</span>
        {showLang && `${localesFlags[lang]} ${localesNames[lang]}`}
      </DropdownToggle>
      <DropdownMenu {...args}>
        {languages.map((language) => {
          return (
            <DropdownItem
              key={language.value}
              onClick={() =>
                language.value !== lang && handleI18nAction(language.value)
              }
              disabled={language.value === lang}
              active={language.value === lang}
              className="nav-link text-center"
            >
              {language.label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

/**  define proptype for the 'translation' function  */
LanguageSwitch.contextTypes = {
  t: PropTypes.func,
};

LanguageSwitch.propTypes = {
  /** showLang */
  showLang: PropTypes.bool,
  /** direction */
  direction: PropTypes.string,
  /** className */
  className: PropTypes.string,
};

LanguageSwitch.defaultProps = {
  showLang: false,
  direction: "down",
  className: "",
};

/** export the component as redux connected component */
export default LanguageSwitch;
