import {
  getNavigationItemAttributes,
  getNavigationItemRelationships,
  getNavigationItems,
} from "@bkry/bowline-redux/navigationItems";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { NavItem, NavLink as NavbarLink } from "reactstrap";
import ParentNavItemDropDown from "./ParentNavItemDropDown";

const ParentNavItem = ({
  navigationItemId,
  handleOnClick,
  isOffcanvas,
  mostSpecificUrl,
  className,
}) => {
  const lang = useSelector((state) => state.i18nState.lang);
  const navigationItemData = useSelector((state) =>
    getNavigationItemAttributes(state, navigationItemId)
  );

  const navigationItemRelationships = useSelector((state) =>
    getNavigationItemRelationships(state, navigationItemId)
  );

  const nestedNavigationItems = useSelector((state) =>
    getNavigationItems(
      state,
      navigationItemRelationships?.children?.data?.map((item) => item.id)
    )
  );

  const isPageContentType = navigationItemData?.content_type === "page";

  return (
    <>
      {nestedNavigationItems?.length ? (
        <ParentNavItemDropDown
          navigationItemData={navigationItemData}
          nestedNavigationItems={nestedNavigationItems}
          handleOnClick={handleOnClick}
          isOffcanvas={isOffcanvas}
          mostSpecificUrl={mostSpecificUrl}
        />
      ) : isPageContentType ? (
        <NavItem
          key={navigationItemId}
          className={`d-flex ${
            isOffcanvas ? "text-decoration-none text-wrap" : ""
          }`}
        >
          <NavLink
            to={`/${lang}/${
              navigationItemData?.url === "/" ? "" : navigationItemData?.url
            }`}
            onClick={handleOnClick}
            className={`nav-link my-1 my-md-0 ${className}`}
            isActive={(match) =>
              !(
                !match ||
                !mostSpecificUrl ||
                mostSpecificUrl.id !== navigationItemId
              )
            }
          >
            {navigationItemData?.title}
          </NavLink>
        </NavItem>
      ) : (
        <NavItem key={navigationItemId} className={`${className}`}>
          <NavbarLink
            href={navigationItemData?.url}
            onClick={handleOnClick}
            target="_blank"
            rel="noopener noreferrer"
            className={`my-1 my-md-0`}
          >
            {navigationItemData?.title}
          </NavbarLink>
        </NavItem>
      )}
    </>
  );
};

ParentNavItem.propTypes = {
  navigationItemId: PropTypes.string,
  handleOnClick: PropTypes.func.isRequired,
  isOffcanvas: PropTypes.bool,
  mostSpecificUrl: PropTypes.object,
  className: PropTypes.string,
};

ParentNavItem.defaultProps = {
  isOffcanvas: false,
  mostSpecificUrl: undefined,
  className: "",
};

export default ParentNavItem;
