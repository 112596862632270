import React from "react";
import PropTypes from "prop-types";
import { DropdownMenu, DropdownToggle, Nav, Dropdown } from "reactstrap";
import HeaderNavItem from "./HeaderNavItem";

const ParentNavItemDropDown = ({
  navigationItemData,
  nestedNavigationItems,
  handleOnClick,
  isOffcanvas,
  mostSpecificUrl,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [changeTimer, setChangeTimer] = React.useState(null);

  const openDropdown = () => {
    setChangeTimer(
      setTimeout(() => {
        setIsOpen(true);
      }, 250)
    );
  };

  const closeDropdown = () => {
    setChangeTimer(
      setTimeout(() => {
        setIsOpen(false);
      }, 250)
    );
  };

  const handleMouseOver = () => {
    // Clear the timer if it's running to prevent automatic closing
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }
    openDropdown();
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    // Clear the timer if it's running
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }
    closeDropdown();
  };

  // Function to handle toggle event
  const handleToggle = () => {
    if (changeTimer) {
      clearTimeout(changeTimer);
      setChangeTimer(null);
    }
    setChangeTimer(
      setTimeout(() => {
        setIsOpen((prevState) => !prevState);
      }, 250)
    );
  };

  return (
    <Dropdown
      direction="down"
      inNavbar
      nav
      isOpen={isOpen}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      toggle={handleToggle}
    >
      <DropdownToggle
        nav
        caret
        color="link"
        className="nav-link text-center my-1 my-md-0"
      >
        {navigationItemData?.title}
      </DropdownToggle>
      <DropdownMenu className="mw-50" style={{ width: "max-content" }}>
        <Nav className="justify-content-around">
          {nestedNavigationItems?.map((childNav, index) => (
            <HeaderNavItem
              key={index}
              navigationItemId={childNav?.id}
              handleOnClick={handleOnClick}
              isOffcanvas={isOffcanvas}
              mostSpecificUrl={mostSpecificUrl}
            />
          ))}
        </Nav>
      </DropdownMenu>
    </Dropdown>
  );
};

ParentNavItemDropDown.propTypes = {
  navigationItemData: PropTypes.object.isRequired,
  nestedNavigationItems: PropTypes.array.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  isOffcanvas: PropTypes.bool.isRequired,
  mostSpecificUrl: PropTypes.string,
};

export default ParentNavItemDropDown;
