/**
 * The navbar container
 */
import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import { NavLink } from "react-router-dom";
import {
  FaInstagram,
  FaXTwitter,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa6";
import { useSelector } from "react-redux";

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const Footer = (props, context) => {
  const lang = useSelector((state) => state.i18nState.lang);

  /** handles the change language event */

  return (
    <div className="footer bg-dark border-top">
      <Container>
        <Row className="py-3 d-flex align-items-center align-items-lg-start small justify-content-center">
          <Col
            className="d-flex flex-column align-items-center align-items-lg-start"
            xs={12}
            lg={4}
          >
            <p className="fw-bold">{context.t("Legal")}</p>
            <NavLink
              to={`/${lang}/terms_and_conditions`}
              className="text-muted p"
            >
              {context.t("Terms & Conditions")}
            </NavLink>

            {lang === "de" && (
              <NavLink to={`/de/mx1/tos`} className="text-muted p">
                {context.t("Aktionsbedingungen")} MX1
              </NavLink>
            )}

            <NavLink to={`/${lang}/tx1/tos`} className="text-muted p">
              {context.t("Aktionsbedingungen")} TX1
            </NavLink>
            <NavLink to={`/${lang}/terms-raffle`} className="text-muted p">
              Teilnahmebedingungen für die COD3X Aktion
            </NavLink>
            <NavLink
              to={`/${lang}/disclaimer_tv_bundle`}
              className="text-muted p"
            >
              {context.t("Disclaimer")} Samsung TV Bundle
            </NavLink>
            {lang === "de" && (
              <a
                href="https://www.samsung.com/de/info/privacy/"
                className="p text-muted"
                target="_blank"
                rel="noreferrer"
              >
                {context.t("Data Privacy")}
              </a>
            )}
            {lang !== "de" && (
              <a
                href="https://www.samsung.com/uk/info/privacy/"
                className="p text-muted"
                target="_blank"
                rel="noreferrer"
              >
                {context.t("Data Privacy")}
              </a>
            )}

            <div id="teconsent"></div>
          </Col>
          <Col
            className="d-flex flex-column align-items-center align-items-lg-start pt-4 pt-lg-0 "
            xs={12}
            lg={4}
          >
            <p className="fw-bold">{context.t("About")}</p>
            <NavLink to={`/${lang}/imprint`} className="text-muted p">
              {context.t("Imprint")}
            </NavLink>
            <NavLink to={`/${lang}/raffle-unlock`} className="text-muted p">
              {context.t("MX1 GE Raffle Winner")}
            </NavLink>
          </Col>
          <Col
            className="d-flex flex-column pt-4 pt-lg-0 align-items-center align-items-lg-start"
            xs={12}
            lg={4}
          >
            <p className="fw-bold">{context.t("Support")}</p>
            <a href="mailto:support.gen3@samsung.com" className="p text-muted">
              {context.t("Contact")}
            </a>
            <NavLink to={`/${lang}/faqs`} className="text-muted p">
              {context.t("FAQs")}
            </NavLink>
          </Col>
        </Row>
      </Container>
      <div className="border-top small text-center border-gray">
        <Container>
          <Row className="d-flex align-items-center mb-4 mt-3 justify-content-center">
            <Col
              lg={4}
              className="text-center text-lg-start pb-3 pb-lg-0"
            ></Col>
            <Col lg={4} className="text-center text-lg-start pb-3 pb-lg-0">
              <p className="small pb-0 mb-0">
                {context.t(
                  "Copyright© 2023 - {currentYear} Samsung. All Rights reserved.",
                  { currentYear: new Date().getUTCFullYear() }
                )}
              </p>
            </Col>
            <Col lg={4} className="text-center text-lg-start pb-3 pb-lg-0">
              <a
                target="_blank"
                href="https://www.facebook.com/SamsungDeutschland"
                rel="noreferrer"
                className="mx-2"
              >
                <FaFacebook size="20px" />
              </a>
              <a
                target="_blank"
                href="https://twitter.com/samsungde"
                rel="noreferrer"
                className="mx-2"
              >
                <FaXTwitter size="20px" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/samsunggermany/"
                rel="noreferrer"
                className="mx-2"
              >
                <FaInstagram size="20px" />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/user/SamsungGermany"
                rel="noreferrer"
                className="mx-2"
              >
                <FaYoutube size="20px" />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Footer.contextTypes = {
  t: PropTypes.func,
};

export default Footer;
