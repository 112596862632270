/**
 * The navbar container
 */
import { BowlineWallet } from "@bkry/bowline-components/BowlineWallet";
import { ConnectWallet } from "@bkry/bowline-components/ConnectWallet";
import { TrunkatedAddress } from "@bkry/bowline-components/Utils";
import { getNavigationItems } from "@bkry/bowline-redux/navigationItems";
import { getProjectRelationships } from "@bkry/bowline-redux/projects";
import { getEnv } from "@bkry/bowline-utils";
import { useWeb3React } from "@web3-react/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import ParentNavItem from "./utils/ParentNavItem";
import LanguageSwitch from "./utils/LanguageSwitch";

const Navigationbar = (props, context) => {
  const REACT_APP_PROJECT_ID = getEnv("REACT_APP_PROJECT_ID");
  const REACT_APP_CDN_PATH = getEnv("REACT_APP_CDN_PATH");
  const lang = useSelector((state) => state.i18nState.lang);
  const { account } = useWeb3React();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const projectRelationships = useSelector((state) =>
    getProjectRelationships(state, REACT_APP_PROJECT_ID)
  );

  const navigationItems = useSelector((state) =>
    getNavigationItems(
      state,
      projectRelationships?.navigation_items?.data?.map((item) => item?.id)
    )
  );

  const parentNavigationItems = navigationItems?.filter((navigationItem) => {
    return !navigationItem?.relationships?.parent?.data;
  });

  const suggestedChainOptions = useSelector(
    (state) => state.projects.defaultNetworks[REACT_APP_PROJECT_ID]
  );

  return (
    <div className="navigationbar navigationbar--sticky">
      <Navbar color="dark" expand="lg">
        <div className="d-flex navbar-brand">
          <NavLink to={`/${lang}`} title={context.t("Home")}>
            <img
              src={
                (REACT_APP_CDN_PATH || "") +
                "/images/Samsung_Orig_Wordmark_WHITE_RGB.png"
              }
              width={150}
              alt="Samsung"
            />
          </NavLink>
        </div>
        <NavbarToggler onClick={toggle} className="navbar-dark" />

        <Collapse navbar isOpen={isOpen} className="bg-black">
          <Nav className="align-items-center ms-auto" navbar>
            {parentNavigationItems?.map((item, index) => {
              return (
                <ParentNavItem
                  key={index}
                  navigationItemId={item?.id}
                  handleOnClick={() => setIsOpen(false)}
                  className="navlink my-1 my-md-0"
                />
              );
            })}
            {!account ? (
              <ConnectWallet
                buttonColor="primary"
                buttonSize="lg"
                buttonId="nav-connect-wallet"
                className="mx-4 ms-md-4 my-1 my-md-0 text-nowrap"
                suggestedChainId={suggestedChainOptions?.chain_id}
              />
            ) : (
              <span className="text-white border border-primary py-3 px-3 fw-bold wallet-address">
                <TrunkatedAddress address={account} />
                <BowlineWallet />
              </span>
            )}
            <LanguageSwitch className="mx-2" />
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Navigationbar.contextTypes = {
  t: PropTypes.func,
};

Navigationbar.propTypes = {
  isSticky: PropTypes.bool,
  style: PropTypes.object,
};

Navigationbar.defaultProps = {};

export default Navigationbar;
